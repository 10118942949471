export enum Types {
    REQUEST_WINNERS = "REQUEST_WINNERS",
    RECEIVE_WINNERS = "RECEIVE_WINNERS",
    ERROR_WINNERS = "ERROR_WINNERS",
    CHECK_WINNERS = "CHECK_WINNERS",
    REQUEST_PROFILE = "REQUEST_PROFILE",
    RECIEVE_PROFILE = "RECIEVE_PROFILE",
    ERROR_PROFILE = "ERROR_PROFILE",
    CHECK_PROFILE = "CHECK_PROFILE",
    SAVE_PROFILE = "SAVE_PROFILE",
    DONE_SAVE_PROFILE = "DONE_SAVE_PROFILE",
    UPDATE_PROFILE = "UPDATE_PROFILE",
    REQUEST_ENTRIES = "REQUEST_ENTRIES",
    RECIEVE_ENTRIES = "RECIEVE_ENTRIES",
    ERROR_ENTRIES = "ERROR_ENTRIES",
    CHECK_ENTRIES = "CHECK_ENTRIES",
    SAVE_ENTRIES = "SAVE_ENTRIES",
    DONE_SAVE_ENTRIES = "DONE_SAVE_ENTRIES",
    UPDATE_ENTRIES = "UPDATE_ENTRIES",
    REQUEST_CATEGORIES = "REQUEST_CATEGORIES",
    RECIEVE_CATEGORIES = "RECIEVE_CATEGORIES",
    ERROR_CATEGORIES = "ERROR_CATEGORIES",
    CHECK_CATEGORIES = "CHECK_CATEGORIES",
    DELETE_ENTRY = "DELETE_ENTRY",
    DELETE_ENRY_DONE = "DELETE_ENRY_DONE",
    REQUEST_CONFIG = "REQUEST_CONFIG",
    RECEIVE_CONFIG = "RECIEVE_CONFIG",
    ERROR_CONFIG = "ERROR_CONFIG",
    CHECK_CONFIG = "CHECK_CONFIG",
    CHANGE_PHASE = "CHANGE_PHASE"
}

